<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box mt20px">
      <div class="content-main">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="人员排班" name="first">
            <ayl-table :table="table">
              <div slot="ctrl-button">
                <el-button class="btn" style="margin-left: 20px" @click="copyScheduling">+ 复制排班</el-button>
                <!-- <el-button plain @click="onExport" style="float:right">导出</el-button> -->
              </div>
            </ayl-table>
          </el-tab-pane>
          <el-tab-pane label="垃圾清扫车车辆排班列表" name="second">
            <ayl-table :table="garbageTable">
              <div slot="ctrl-button">
                <!-- <el-button plain @click="onExport" style="float:right">导出</el-button> -->
              </div>
            </ayl-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <ayl-dialog
      :visible.sync="isShowDialog"
      :viewUrl="viewUrlForDialog"
      v-model="dataForDialog"
      :async="true"
      :showBottomBtn="false"
      :width="widthForDialog"
    ></ayl-dialog>
  </div>
</template>

<script>
export default {
  data() {
    const vm = this;
    return {
      nav: [{ name: "排班管理" }],
      debug: 0,
      activeName: "first",
      //弹窗有关
      viewUrlForDialog: ``,
      widthForDialog: "50%",
      isShowDialog: false,
      dataForDialog: null,
      //人员排班
      table: {
        api: vm.$api_hw.scheduling_getPersonShiftScheduleList,
        query: {
          bidId: null, //关联标段编号
          gridId: null, //关联网格编号
          scheduleMonth: null //查询月份 格式202005
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "cascader_radio",
            title: "标段名称",
            model: "bidId",
            placeholder: "请选择",
            option: [],
            width: "180px"
          },
          {
            type: "select",
            title: "网格名称",
            model: "gridId",
            placeholder: "请选择",
            option: []
          },
          {
            type: "date",
            title: "排班月份",
            model: "scheduleMonth",
            dateType: "month",
            valueFormat: "yyyyMM"
          }
        ],
        columns: [
          {
            title: "序号",
            width: "50px",
            $index: "index"
          },
          {
            title: "网格名称",
            render(h, ctx) {
              return h("span", ctx.row.sanitationShiftManageVO.gridName);
            }
          },
          {
            title: "所属标段",
            width: "100px",
            render(h, ctx) {
              return h("span", ctx.row.sanitationShiftManageVO.bidName);
            }
          },
          {
            title: "班次名称",
            width: "100px",
            render(h, ctx) {
              return h("span", ctx.row.sanitationShiftManageVO.shiftName);
            }
          },
          {
            title: "班次时段",
            width: "120px",
            render(h, ctx) {
              return h(
                "span",
                `${ctx.row.sanitationShiftManageVO.shiftStartTime}—${ctx.row.sanitationShiftManageVO.shiftEndTime}`
              );
            }
          }
        ]
      },
      //垃圾清扫车车辆排班列表
      garbageTable: {
        api: vm.$api_hw.scheduling_getRouteJobTypeScheduleList,
        query: {
          bidId: null,
          routeId: null,
          type: null
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "cascader_radio",
            title: "标段名称",
            model: "bidId",
            placeholder: "请选择",
            option: [],
            width: "180px"
          },
          {
            type: "select",
            title: "线路名称",
            model: "routeId",
            placeholder: "请选择",
            option: []
          },
          {
            type: "select",
            title: "作业类型",
            model: "type",
            placeholder: "请选择",
            option: this.$enums_hw.jobType.list
          }
        ],
        columns: [
          {
            title: "序号",
            width: "50px",
            $index: "index"
          },
          {
            title: "车辆路线",
            key: "routeName"
          },
          {
            title: "所属标段",
            key: "bidName"
          },
          {
            title: "作业类型",
            key: "routeTypeDes",
            width: "100px"
          },
          {
            title: "排班车辆",
            width: "100px",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view-hw": true
                    },
                    on: {
                      click: vm.vehicleClick.bind(this, ctx.row)
                    }
                  },
                  ctx.row.routeType === "0" || ctx.row.routeType === 0
                    ? ctx.row.dutySprinklerCar || "[请排班]"
                    : ctx.row.dutySweeperCar || "[请排班]"
                )
              ]);
            }
          },
          {
            title: "排班司机",
            width: "100px",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view-hw": true
                    },
                    on: {
                      click: vm.personnelClick.bind(this, ctx.row)
                    }
                  },
                  ctx.row.routeType === "0" || ctx.row.routeType === 0
                    ? ctx.row.dutySprinklerDriver || "[请排班]"
                    : ctx.row.dutySweeperDriver || "[请排班]"
                )
              ]);
            }
          }
        ]
      }
    };
  },
  methods: {
    handleClick(tab, event) {},
    //车辆排班
    vehicleClick(row) {
      //传views的url
      this.viewUrlForDialog = "/district-management/scheduling/vehicle-list";
      this.widthForDialog = "50%";
      //传参给弹窗
      this.dataForDialog = {
        type: row.routeType, //作业类型
        routeId: row.routeId,
        bidId: row.bidId,
        carTypeList: ["21", "22"]
      };
      //显示弹窗
      this.isShowDialog = true;
    },

    //人员排班
    personnelClick(row) {
      //传views的url
      this.viewUrlForDialog = "/district-management/scheduling/driver-list";
      this.widthForDialog = "50%";
      //传参给弹窗
      this.dataForDialog = {
        type: row.routeType, //作业类型
        routeId: row.routeId, //personTypeList
        bidId: row.bidId,
        personTypeList: ["9", "10"] //personTypeList
      };
      //显示弹窗
      this.isShowDialog = true;
      // debugger
    },

    //导出
    onExport() {},
    personnelScheduling(a, b, c, d, e) {
      //传views的url
      this.viewUrlForDialog =
        "/district-management/scheduling/personnel-scheduling";
      this.widthForDialog = "70%";
      //传参给弹窗
      this.dataForDialog = {
        shiftId: a,
        scheduleDay: b,
        bidId: c,
        gridId: d,
        scheduleId: e
      };
      //显示弹窗
      this.isShowDialog = true;
    },

    //复制排班人员
    copyScheduling() {
      this.$router.push({
        path: "/district-management/scheduling/copy-scheduling"
      });
    }
  },

  watch: {
    table: {
      deep: true,
      handler: function(v, ov) {
        this.log("----------watch-------", v, ov);
        this.$nextTick(function() {
          document.querySelectorAll(`td>div>span.orange`).forEach(dom => {
            dom.parentNode.parentNode.style.background = "#ff8c1a";
          });
          document.querySelectorAll(`td>div>span.normal`).forEach(dom => {
            dom.parentNode.parentNode.style.background = "#ffffff";
          });
        });
      }
    }
  },

  async created() {
    let self = this;
    //动态加表头1~31日
    for (let i = 0; i < 31; i++) {
      (function(j) {
        let column = {
          title: `${j + 1}日`,
          //   key: 'status',
          width: "100px",
          align: "center",
          renderHeader(h, { column, $index }) {
            return h(
              "span",
              {
                class: new Date().getDate() == j + 1 ? `orangeText` : ``
              },
              `${j + 1}日`
            );
          },
          children: [
            {
              title: "应配人数",
              width: "80px",
              render(h, ctx) {
                return h(
                  "span",
                  {
                    class:
                      parseInt(
                        ctx.row.sanitationScheduleDistributeVOList[j].arrangeNum
                      ) >
                      parseInt(
                        ctx.row.sanitationScheduleDistributeVOList[j].realNum
                      )
                        ? `orange`
                        : `normal`
                  },
                  ctx.row.sanitationScheduleDistributeVOList[j].arrangeNum
                );
              }
            },
            {
              title: "实配人数",
              width: "80px",
              render(h, ctx) {
                return h(
                  "span",
                  {
                    class:
                      parseInt(
                        ctx.row.sanitationScheduleDistributeVOList[j].arrangeNum
                      ) >
                      parseInt(
                        ctx.row.sanitationScheduleDistributeVOList[j].realNum
                      )
                        ? `orange`
                        : `normal`
                  },
                  ctx.row.sanitationScheduleDistributeVOList[j].realNum
                );
              }
            },
            {
              title: "排班人员",
              width: "100px",
              render(h, ctx) {
                let strs =
                  ctx.row.sanitationScheduleDistributeVOList[j].personIdList;
                let doms = [];
                if (!strs) {
                  strs = `[请排班]`;
                }
                // debugger;
                if (strs) {
                  let arr = strs.split(",");
                  arr.forEach(item => {
                    if (item) {
                      let _d = h(
                        "span",
                        {
                          class: {
                            "table-view-hw": true
                          },
                          on: {
                            click: self.personnelScheduling.bind(
                              this,
                              ctx.row.sanitationScheduleDistributeVOList[j]
                                .shiftId,
                              ctx.row.sanitationScheduleDistributeVOList[j]
                                .scheduleDay,
                              ctx.row.sanitationShiftManageVO.bidId,
                              ctx.row.sanitationShiftManageVO.gridId,
                              ctx.row.sanitationScheduleDistributeVOList[j]
                                .scheduleId
                            )
                          }
                        },
                        item
                      );
                      doms.push(_d);
                    }
                  });
                }

                return h("span", doms);
              }
            }
          ]
        };
        self.table.columns.push(column);
      })(i);
    }

    try {
      // await this.$search(this.table);
      // await this.$search(this.garbageTable);

      self.table.searchData[0].option = self.garbageTable.searchData[0].option = await self.$api_hw.common_getBidNameAndIdList(
        {}
      );
      self.table.searchData[1].option = await self.$api_hw.common_getDistinctGrid(
        {}
      );
      self.garbageTable.searchData[1].option = await self.$api_hw.common_getDistinctRoute(
        {}
      );
    } catch (e) {
      console.error(e);
    }
  },

  async mounted() {
    let self = this;
    BUS.$on(BUSEVENT.REFRESH_SCHEDULING_LIST, d => {
      switch (d) {
        case 0:
          self.$search(self.table);
          break;
        case 1:
          self.$search(self.garbageTable);
          break;
        default:
          self.$search(self.table);
          self.$search(self.garbageTable);
          break;
      }
    });
  }
};
</script>

<style lang='sass' scoped>
.btn
  height: 28px
  border: 1px solid rgba(26,188,156,1)
  border-radius: 4px
  color: #1ABC9C
  
  .drawer-main
    margin: 5px 15px
    .drawer-btn
      width: 92px
      height: 36px
      margin-bottom: 10px
      background: rgba(237,237,237,1)
      border-radius: 2px
      outline: none
      border: 0
      &:hover
        margin-bottom: 10px
        background: rgba(26,188,156,1)
        border-radius: 2px
        border: 0
        color: #fff
      &:before
        margin-bottom: 10px
        background: rgba(26,188,156,1)
        border-radius: 2px
        border: 0
        color: #fff
    .drawer-btn1
      width: 92px
      height: 36px
      margin-bottom: 10px
      background: rgba(26,188,156,1)
      border-radius: 2px
      outline: none
      border: 1px solid transparent
      color: #fff

  .drawer-contract
    margin: 30px 15px 10px
    .drawer-contract-item
      width: 100%
      .drawer-contract-span
        color: #2E3033
    .drawer-contract-item1
      width: 48%
      .drawer-contract-span
        color: #2E3033
  .drawer-hr
    width: 100%
    height: 1px
    background: rgba(232,232,232,1)
  .drawer-map
    margin: 20px 15px 10px
    .drawer-map-title
      color: rgba(46,48,51,1)
  /deep/.el-drawer__header 
      margin-bottom: 15px
      padding-left: 15px
      color: #222
      font-size: 16px
  /deep/.el-button--success 
      color: #FFFFFF
      background-color: rgba(26,188,156,1)
      border-color: rgba(26,188,156,1)

  .my-table
    .scope-class
      color: rgba(26,188,156,1)

/deep/.el-tabs__item.is-active
      color: rgba(26,188,156,1)
/deep/.el-tabs__active-bar
      background-color: rgba(26,188,156,1)
/deep/.el-tabs__item:hover
      color: rgba(26,188,156,1)
      cursor: pointer

</style>
<style>
.orangeText {
  color: #ff8c1a;
}
</style>